<template>
  <!-- prettier-ignore -->
  <div
    class="gender"
    :class="classes"
  >
    <template v-if="isIconSelection">
      <div class="gender__element">
        <label
          class="gender__item"
          :class="{
            'gender__item--disabled': isGenderDisabled,
            'gender__item--selected': isFemale,
          }"
        >
          <input
            v-model="gender"
            class="gender__input"
            name="gender"
            type="radio"
            :checked="isFemale"
            :value="GENDER.FEMALE"
          />
          <the-icon
            class="gender__icon"
            art="solid"
            name="person-dress-simple"
          />
          <span v-text="$t('person.gender.female')" />
        </label>

        <label
          class="gender__item"
          :class="{
            'gender__item--disabled': isGenderDisabled,
            'gender__item--selected': isMale,
          }"
        >
          <input
            v-model="gender"
            class="gender__input"
            name="gender"
            type="radio"
            :checked="isMale"
            :value="GENDER.MALE"
          />
          <the-icon
            class="gender__icon"
            art="solid"
            name="person-simple"
          />
          <span v-text="$t('person.gender.male')" />
        </label>
        <template v-if="!$props.disableUnborn">
          <label
            class="gender__item"
            :class="{
              'gender__item--disabled': isUnbornDisabled,
              'gender__item--selected': gender === GENDER.UNBORN,
            }"
          >
            <input
              v-model="gender"
              class="gender__input"
              name="gender"
              type="radio"
              :checked="gender === GENDER.UNBORN"
              :value="GENDER.UNBORN"
            />
            <the-icon
              class="gender__icon"
              art="solid"
              name="baby-carriage"
            />
            <span v-text="$t('person.gender.unborn')" />
          </label>
        </template>
      </div>
      <the-typography type="bodyLargeShort">
        <div
          class="gender__hint"
          v-text="autoHint"
        />
      </the-typography>
    </template>
    <template v-else>
      <div class="gender__select">
        <form-dropdown
          v-model="gender"
          class="gender__dropdown"
          name="genderSelect"
          required
          :options="genderList"
          :readonly="$props.disabled"
          :theme="selectTheme"
          :title-label="$t('person.sex')"
          :validate="$props.validate"
          @update:modelValue="select"
        />
      </div>
    </template>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'

import useBrowser from '@/hooks/useBrowser'
import useI18n from '@/hooks/useI18n'

import FormDropdown from '@/components/Form/Dropdown'

import { GENDER } from '@/config/constants'

// HOOKS
const { browser } = useBrowser()
const { t } = useI18n()

// INIT
const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  // show male / female by default
  age: {
    type: Number,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  disableUnborn: {
    type: Boolean,
    default: false,
  },
  forcedDropdown: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: String,
    default: '',
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  theme: {
    type: String,
    default: 'transparent',
  },
  validate: {
    type: Object,
    default: () => {},
  },
})

// DATA
let gender = ref(props.modelValue)

// COMPUTED
const autoHint = computed(() => {
  if (props.validate && isInvalid.value) {
    const errors = Array.from(props.validate.$errors)
    const error = errors[0]?.$validator

    if (error) {
      return t(`form.error.${error}`)
    }
  }
  return props.hint
})

const classes = computed(() => {
  return {
    'gender--invalid': isInvalid.value,
    'gender--readonly': props.readonly,
    [`gender--${props.theme}`]: true,
  }
})

const genderList = computed(() => {
  return Object.values(GENDER)
    .map(val => {
      return {
        value: val,
        text: t(`person.gender.${val.toLowerCase()}`),
      }
    })
    .filter(g => {
      const isUnbornGender = g.value === GENDER.UNBORN

      if (isUnbornDisabled.value || props.disableUnborn) {
        // select others than unborn
        return !isUnbornGender
      } else if (isGenderDisabled.value) {
        // select unborn
        return isUnbornGender
      } else {
        // keep all
        return g
      }
    })
})

const isFemale = computed(() => {
  return gender.value === GENDER.FEMALE
})

const isGenderDisabled = computed(() => {
  return props.age < 0
})

const isIconSelection = computed(() => {
  return browser.isDesktop && !props.forcedDropdown
})

const isInvalid = computed(() => {
  return props.validate?.$invalid && props.validate?.$error
})

const isMale = computed(() => {
  return gender.value === GENDER.MALE
})

const isUnborn = computed(() => {
  return gender.value === GENDER.UNBORN
})

const isUnbornDisabled = computed(() => {
  return props.age !== null && props.age >= 0
})

const selectTheme = computed(() => {
  return ['transparent', 'light'].includes(props.theme) ? 'dark' : 'light'
})

// METHODS
function autoSelect() {
  if (props.age < 0) {
    select(GENDER.UNBORN)
  } else if (props.age >= 0 && isUnborn.value) {
    select(null)
  }
}

function select(value) {
  gender.value = value
  props.validate?.$touch()
  emit('update:modelValue', value)
}

// WATCHERS
watch(
  () => props.age,
  () => autoSelect()
)

watch(gender, value => {
  select(value)
})

watch(
  () => props.modelValue,
  value => {
    if (value !== gender.value) {
      gender.value = value
    }
  }
)
</script>

<style name="mobile" scoped>
.gender--invalid {
  & .gender__icon,
  & .gender__hint {
    color: var(--c-secondary-color-1);
  }
}

.gender--readonly {
  pointer-events: none;
}

.gender--transparent {
  background-color: transparent;
}

.gender--light {
  background-color: var(--c-primary-neutral-3);
}

.gender--dark {
  background-color: var(--c-primary-neutral-2);
}

.gender__element {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;
  padding: 0 0 0 10px;
}

.gender__item {
  color: var(--c-primary-neutral-1);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.gender__item:hover,
.gender__item--selected {
  color: var(--c-primary-color-3);
}

.gender__item--selected {
  cursor: default;
}

.gender__item--disabled {
  pointer-events: none;
  opacity: 0.7;
}

.gender--invalid .gender__item--disabled .gender__icon {
  color: inherit;
}

.gender__input {
  position: absolute;
  display: inline-block;
  opacity: 0;
  height: 1px;
  width: 1px;
}

.gender__icon {
  font-size: 25px;
  color: inherit;
}

.gender__hint {
  position: relative;
  padding: 0 0 0 10px;
}

.unborn__tooltip {
  margin-left: -24px;
}

.gender__select {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.gender__dropdown {
  flex: 1 0 auto;
}
</style>
